export const months = [
  {
    value: "",
    name: "---",
  },
  {
    value: "jan",
    name: "Gennaio",
  },
  {
    value: "feb",
    name: "Febbraio",
  },
  {
    value: "mar",
    name: "Marzo",
  },
  {
    value: "apr",
    name: "Aprile",
  },
  {
    value: "may",
    name: "Maggio",
  },
  {
    value: "jun",
    name: "Giugno",
  },
  {
    value: "jul",
    name: "Luglio",
  },
  {
    value: "aug",
    name: "Agosto",
  },
  {
    value: "sep",
    name: "Settembre",
  },
  {
    value: "oct",
    name: "Ottobre",
  },
  {
    value: "nov",
    name: "Novembre",
  },
  {
    value: "dec",
    name: "Dicembre",
  },
];

const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const years = range(currentYear, currentYear + 10, +1);
