import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { months, years } from "../data/date";

const Form = ({ data, location, pageContext }) => {
  const { title, lang } = data.markdownRemark.frontmatter;
  const menu = {
    main: data.allMainMenuJson.edges,
    service: data.allServiceMenuJson.edges,
    footer: data.allFooterMenuJson.edges,
    subFooter: data.allSubFooterMenuJson.edges,
  };
  const { slug } = pageContext;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ defaultValues: { rinnovo: "rinnovo" } });
  const rinnovo = watch("rinnovo");
  const pay_opt = watch("pay_opt");
  const [apiError, setApiError] = useState();
  const [apiSuccess, setApiSuccess] = useState();

  // useEffect(() => {
  //   console.log("test", errors);
  // }, [isSubmitting]);
  const onSubmit = (data) => {
    const formData = new FormData();

    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    fetch("/api/form-rinnovo.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((json) => setApiSuccess(json))
      .catch((error) => setApiError(error));
  };

  return (
    <Layout
      bodyClass="page-default-single"
      lang={lang}
      menu={menu}
      pageTitle={title}
      slug={slug}
    >
      <div className="pb-5">
        <div className="title-wrapper">
          <div className="container container-height">
            <div className="row h-100">
              <div className="col-12 col-md-8 my-auto">
                <div>
                  <h1 className="title text-white">
                    <strong>{title}</strong>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {isSubmitSuccessful ? (
            <div>
              {apiSuccess && (
                <div className="my-6">
                  Modulo inviato correttamente, prenderemo in carico la richiesta al
                  pi&#249; presto possibile.
                </div>
              )}
              {apiError && (
                <div className="my-6">
                  {" "}
                  Siamo spiacenti, un errore sul server ha impedito il completamento
                  dell'operazione. Si prega di riprovare pi&#249; tardi.
                </div>
              )}
            </div>
          ) : (
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              // action="#"
              noValidate
            >
              <div className="row justify-content-start pt-6">
                <div className="col-12 col-lg-8 mb-3">
                  <div className="content">
                    Per richiedere o rinnovare il vostro abbonamento INRETE è sufficiente
                    compilare il form sottostante (<span className="text-danger">*</span>{" "}
                    campi obbligatori).
                  </div>
                </div>
              </div>
              <div className="sub-title-color my-2">
                <strong>Dati personali</strong>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="nome" className="col-sm-3 col-form-label">
                  <span className="text-danger">*</span> Nome:
                </label>
                <div className="col-sm-7">
                  <input
                    className={
                      errors.nome
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    {...register("nome", {
                      required: "Campo obbligatorio",
                    })}
                  />

                  <div className="invalid-feedback">{errors?.nome?.message}</div>
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="cognome" className="col-sm-3 col-form-label">
                  <span className="text-danger">*</span> Cognome:
                </label>
                <div className="col-sm-7">
                  <input
                    className={
                      errors.cognome
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    {...register("cognome", {
                      required: "Campo obbligatorio",
                    })}
                  />

                  <div className="invalid-feedback">{errors?.nome?.message}</div>
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="tel" className="col-sm-3 col-form-label">
                  <span className="text-danger">*</span> Telefono:
                </label>
                <div className="col-sm-7">
                  <input
                    className={
                      errors.tel
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    {...register("tel", {
                      required: "Campo obbligatorio",
                    })}
                  />

                  <div className="invalid-feedback">{errors?.tel?.message}</div>
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="addr" className="col-sm-3 col-form-label">
                  Indirizzo:
                </label>
                <div className="col-sm-7">
                  <input className="form-control form-control-sm" {...register("addr")} />
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="cap" className="col-sm-3 col-form-label">
                  CAP:
                </label>
                <div className="col-sm-7">
                  <input className="form-control form-control-sm" {...register("cap")} />
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="cfpiva" className="col-sm-3 col-form-label">
                  Codice Fiscale/Partita IVA:
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control form-control-sm"
                    {...register("cfpiva")}
                  />
                </div>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="email" className="col-sm-3 col-form-label">
                  Email:
                </label>
                <div className="col-sm-7">
                  <input
                    className={
                      errors.email
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    {...register("email", { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i })}
                  />

                  <div className="invalid-feedback">
                    {errors.email && "Formato email non valido"}
                  </div>
                </div>
              </div>

              <div className="sub-title-color mt-3 mb-2">
                <strong>Tipo di abbonamento</strong>
              </div>

              <div className="form-group mb-1 row">
                <label htmlFor="subtype" className="col-sm-3 col-form-label">
                  <span className="text-danger">*</span> Scegli l'abbonamento:
                </label>
                <div className="col-sm-7">
                  <select
                    className={
                      errors.subtype
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    {...register("subtype", {
                      required: "Campo obbligatorio",
                    })}
                  >
                    <option value="">---</option>
                    <option value="E-MAIL ADDRESS 1 casella di posta (31,11 euro)">
                      E-MAIL ADDRESS 1 casella di posta (31,11 euro)
                    </option>
                    <option value="Servizio Antivirus (30,50 euro)">
                      Servizio Antivirus (30,50 euro)
                    </option>
                    <option value="Servizio Antispam (30,50 euro)">
                      Servizio Antispam (30,50 euro)
                    </option>
                    <option value="Casella PEC 30 Mb (42,70 euro)">
                      Casella PEC 30 Mb (42,70 euro)
                    </option>
                    <option value="Casella PEC 100 Mb (48,80 euro)">
                      Casella PEC 100 Mb (48,80 euro)
                    </option>
                    <option value="Casella PEC 500 Mb (61,00 euro)">
                      Casella PEC 500 Mb (61,00 euro)
                    </option>
                  </select>
                  <div className="invalid-feedback">{errors?.tel?.message}</div>
                </div>
              </div>

              <div className="row justify-content-start mb-1">
                <div className="col-8">
                  <div className="content">
                    Indicare se si vuole richiedere un nuovo abbonamento o se si vuole
                    effettuare un rinnovo.
                  </div>
                </div>
              </div>

              <div className="custom-control custom-radio">
                <input
                  {...register("rinnovo")}
                  className="custom-control-input"
                  type="radio"
                  id="flexRadioDefault1"
                  value="rinnovo"
                />
                <label className="custom-control-label" htmlFor="flexRadioDefault1">
                  Rinnovo
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  {...register("rinnovo")}
                  className="custom-control-input"
                  type="radio"
                  id="flexRadioDefault2"
                  value="nuova_richiesta"
                />
                <label className="custom-control-label" htmlFor="flexRadioDefault2">
                  Nuovo abbonamento
                </label>
              </div>
              {rinnovo == "nuova_richiesta" && (
                <div className="form-group mb-1 row">
                  <label htmlFor="sublogin" className="col-sm-3 col-form-label">
                    <span className="text-danger">*</span> Login desiderato:
                  </label>
                  <div className="col-sm-7">
                    <input
                      className={
                        errors.sublogin
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      {...register("sublogin", {
                        required: "Campo obbligatorio",
                      })}
                    />

                    <div className="invalid-feedback">{errors?.sublogin?.message}</div>
                  </div>
                </div>
              )}

              <div className="sub-title-color mt-3 mb-2">
                <strong>Modalità di pagamento</strong>
              </div>
              <div className="custom-control custom-radio">
                <input
                  {...register("pay_opt")}
                  className="custom-control-input"
                  type="radio"
                  id="pay_opt_bb"
                  value="BB"
                />
                <label className="custom-control-label" htmlFor="pay_opt_bb">
                  Bonifico bancario
                </label>
              </div>
              {pay_opt == "BB" && (
                <div className="ml-1 col-6 mb-1">
                  Pagamento con bonifico bancario sul conto corrente intestato ad INRETE,
                  presso la Dipendenza di Rosta della Banca Sella. <br />
                  IBAN: <strong>IT 30 B 03268 31260 001196716250</strong>
                  <br />
                  Indicare come causale “Abbonamento INRETE”.
                </div>
              )}
              <div className="custom-control custom-radio">
                <input
                  {...register("pay_opt")}
                  className="custom-control-input"
                  type="radio"
                  id="pay_opt_cc"
                  value="cc"
                />
                <label className="custom-control-label" htmlFor="pay_opt_cc">
                  Carta di credito
                </label>
              </div>
              {pay_opt == "cc" && (
                <>
                  <div className="form-group mb-1 row ml-1">
                    <label className="col-sm-2 col-form-label">
                      <span className="text-danger">*</span> Tipo:
                    </label>

                    <div className="col-sm-7">
                      <select
                        className={
                          errors.cc_type
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_type", {
                          required: "Campo obbligatorio",
                        })}
                      >
                        <option value="">---</option>
                        <option value="VISA">VISA</option>
                        <option value="MasterCard">Master Card</option>
                        <option value="Carta SI">Carta SI</option>
                      </select>
                      <div className="invalid-feedback">{errors?.cc_type?.message}</div>
                    </div>
                  </div>
                  <div className="form-group mb-1 row ml-1">
                    <label className="col-sm-2 col-form-label">
                      <span className="text-danger">*</span> Numero:
                    </label>
                    <div className="col-sm-3">
                      <input
                        className={
                          errors.cc_num
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_num", {
                          required: "Campo obbligatorio",
                          pattern: {
                            value: /^(?=.*\d)[\d ]+$/,
                            message: "Numero non valido",
                          },
                        })}
                      />
                      <div className="invalid-feedback">{errors?.cc_num?.message}</div>
                    </div>
                    <label className="col-sm-2 col-form-label text-right">
                      <span className="text-danger">*</span> Cod CV2:
                    </label>
                    <div className="col-sm-2">
                      <input
                        className={
                          errors.cc_cv2
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_cv2", {
                          required: "Campo obbligatorio",
                        })}
                      />
                      <div className="invalid-feedback">{errors?.cc_cv2?.message}</div>
                    </div>
                  </div>

                  <div className="form-group mb-1 row ml-1">
                    <label className="col-sm-2 col-form-label">
                      <span className="text-danger">*</span> Validità:
                    </label>
                    <div className="col-sm-2">
                      <select
                        name="cc_exp_m"
                        className={
                          errors.cc_exp_m
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_exp_m", {
                          required: "Campo obbligatorio",
                        })}
                      >
                        {months.map((month, id) => (
                          <option key={month.value} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">{errors?.cc_exp_m?.message}</div>
                    </div>

                    <div className="col-sm-2">
                      <select
                        name="cc_exp_y"
                        className={
                          errors.cc_exp_y
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_exp_y", {
                          required: "Campo obbligatorio",
                        })}
                      >
                        <option value="">---</option>
                        {years.map((year, id) => (
                          <option key={year.toString()} value={year.toString()}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">{errors?.cc_exp_y?.message}</div>
                    </div>
                  </div>

                  <div className="form-group mb-1 row ml-1">
                    <label className="col-sm-2 col-form-label">
                      <span className="text-danger">*</span> Intestatario:
                    </label>

                    <div className="col-sm-7">
                      <input
                        className={
                          errors.cc_owner
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        {...register("cc_owner", {
                          required: "Campo obbligatorio",
                        })}
                      />
                      <div className="invalid-feedback">{errors?.cc_owner?.message}</div>
                    </div>
                  </div>
                </>
              )}

              <div className="sub-title-color my-2">
                <strong>Note</strong>
              </div>
              <div className="form-group mb-1 row">
                <div className="col-sm-10">
                  <textarea
                    className="form-control form-control-sm"
                    rows="3"
                    name="note"
                    {...register("note")}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-1"
                disabled={isSubmitting}
              >
                Invia richiesta
              </button>
            </form>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $lang: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        path
        lang
        tag_cloud
      }
      fields {
        slug
      }
      rawMarkdownBody
    }
    allMainMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            url
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allFooterMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allServiceMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            png_icon
            url
          }
        }
      }
    }
    allSubFooterMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
        }
      }
    }
  }
`;

export default Form;
